import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { APP_ROUTES } from "@app/core";
import { PrivateBetaReverseGuard } from "./core/authentication/private-beta-reverse.guard";
import { PrivateBetaGuard } from "./core/authentication/private-beta.guard";

const routes: Routes = [
  // Fallback when no prior route is matched
  {
    path: "",
    loadChildren: () => import("app/static-pages/static-pages.module").then((m) => m.StaticPagesModule),
    canActivate: [PrivateBetaGuard],
  },
  {
    path: APP_ROUTES.LISTINGS.module,
    loadChildren: () => import("app/listings/listings.module").then((m) => m.ListingsModule),
    canActivate: [PrivateBetaGuard],
  },
  {
    path: APP_ROUTES.MY_ACCOUNT.module,
    loadChildren: () => import("app/my-account/my-account.module").then((m) => m.MyAccountModule),
    canActivate: [PrivateBetaGuard],
  },
  {
    path: APP_ROUTES.BLOG.module,
    loadChildren: () => import("app/blog/list/blog-list.module").then((m) => m.BlogListModule),
    canActivate: [PrivateBetaGuard],
  },
  {
    path: APP_ROUTES.BLOG_DETAILS.module,
    loadChildren: () => import("app/blog/details/blog-details.module").then((m) => m.BlogDetailsModule),
    canActivate: [PrivateBetaGuard],
  },
  {
    path: APP_ROUTES.FAQ.module,
    loadChildren: () => import("app/faq/faq.module").then((m) => m.FaqModule),
    canActivate: [PrivateBetaGuard],
  },
  {
    path: APP_ROUTES.BETA_PRIVATE.module,
    loadChildren: () => import("app/beta-private/beta-private.module").then((m) => m.BetaPrivateModule),
    canActivate: [PrivateBetaReverseGuard],
  },

  // wildcard redirects to home-page, and because of lazy loading must be last
  { path: "**", redirectTo: "", pathMatch: "full", canActivate: [PrivateBetaGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: "enabled" })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
