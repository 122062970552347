import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Cart } from "@app/models/Cart";
import { map } from "rxjs/operators";
import { Order, Paged } from "@app/models";
import { Logger } from "@app/core/logger.service";
import ShippingRate = Cart.ShippingRate;

const logger = new Logger("OrderService");

@Injectable({
  providedIn: "root",
})
export class OrderService {
  constructor(public httpClient: HttpClient) {}

  indexMade(params: HttpParams): Observable<Paged<Order>> {
    return this.httpClient
      .get("/account/order/made", { params: params })
      .pipe(map((response: Paged<Order>) => response));
  }

  create(shippingRate: ShippingRate) {
    return this.httpClient.post("/account/order", shippingRate).pipe(map((response) => response));
  }

  get(orderId: string) {
    return this.httpClient.get(`/account/order/${orderId}`).pipe(map((response: Order) => response));
  }

  getByPaymentId(params: any) {
    return this.httpClient.post(`/account/order/process-payment/`, params).pipe(map((response: Order) => response));
  }

  indexReceived(params: HttpParams): Observable<Paged<Order>> {
    return this.httpClient
      .get("/account/order/received", { params: params })
      .pipe(map((response: Paged<Order>) => response));
  }

  generateShippingLabel(orderId: number) {
    return this.httpClient.get(`/account/order/${orderId}/shippingLabel`).pipe(map((response: Order) => response));
  }

  setShipped(orderId: number, tracking_url?: string) {
    const params = tracking_url ? { tracking_url: tracking_url } : {};
    return this.httpClient
      .post(`/account/order/${orderId}/setShipped`, params)
      .pipe(map((response: Order) => response));
  }

  updateShipping(orderId: number, data: object) {
    return this.httpClient
      .patch(`/account/order/${orderId}/shippingDetails`, data)
      .pipe(
        map((response: Order) => response)
      );
  }

  payAgain(orderId: string) {
    return this.httpClient.get(`/account/order/${orderId}/payAgain`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  rateOrder(orderId: string, rating: number, comment: string) {
    return this.httpClient
      .post(`/account/order/${orderId}/rate`, { rating: rating, comment: comment })
      .pipe(map((r) => r));
  }

  getInvoice(order: Order) {
    let headers = new HttpHeaders();
    headers = headers.set("Accept", "application/pdf");
    return this.httpClient
      .get(`/account/order/${order.id}/invoice`, { headers: headers, responseType: "blob" as "json" })
      .pipe(map((r) => r));
  }

  cancel(orderId: number) {
    return this.httpClient.post(`/account/order/${orderId}/cancel`, {}).pipe(map((response: Order) => response));
  }
}
