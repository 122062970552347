<!--
<mat-drawer-container>
  <mat-drawer mode="side" opened="true" class="mat-elevation-z4">
    <app-my-account-sidebar></app-my-account-sidebar>
    </mat-drawer>
  <mat-drawer-content>
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-drawer-content>

</mat-drawer-container>
-->
<app-header></app-header>

<router-outlet></router-outlet>
<app-footer></app-footer>

