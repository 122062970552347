import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';

// TODO https://alligator.io/angular/providers-shared-modules/

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  exports: []
})
export class ApiModule {


  constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import API module in the AppModule only.`);
    }
  }
}
