import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { APP_ROUTES } from "@app/core";
import { distinctUntilChanged } from "rxjs/operators";
import { BehaviorSubjectsService } from "./behavior-subjects.service";

@Injectable({
  providedIn: "root",
})
export class PrivateBetaGuard implements CanActivate {
  testing_permissions = true;

  APP_ROUTES = APP_ROUTES;
  constructor(private behaviorSubjectsService: BehaviorSubjectsService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.behaviorSubjectsService.turnOnPrivateMode.pipe(distinctUntilChanged()).subscribe((res: boolean) => {
      if (res) {
        this.testing_permissions = false;
        this.router.navigate([APP_ROUTES.BETA_PRIVATE.path]);
      }
    });

    return this.testing_permissions;
  }
}
