<div class="dialog--container">
  <div fxFlex="100" style="text-align: center;">
    <app-loader [isLoading]="isLoading" class="inline-loader"></app-loader>
  </div>
  <h1 *ngIf="page && page.description" mat-dialog-title>{{ page.description }}</h1>
  <div *ngIf="page && page.content" [innerHtml]="page.content" mat-dialog-content>
  </div>
  <div flex fxLayoutAlign="flex-end center" mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>Close</button>
  </div>
</div>
