import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Account} from '@app/models';
import {Logger} from '@app/core';

//const logger = new Logger('ReportService');

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private httpClient: HttpClient) {
  }

  addReport(reportable: any, message: string) {
    return this.httpClient
      .post(`/report`, {
        reportable_type: reportable.reportable_type,
        reportable_id: reportable.id,
        message: message
      })
      .pipe(
        map((response) => response)
      );
  }

}
