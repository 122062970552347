import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { merge } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { Angulartics2GoogleAnalytics } from "angulartics2/ga";
import { environment } from "@env/environment";
import { AuthenticationService, I18nService, Logger, UpdateService } from "@app/core";
import { CategoryService } from "@app/api";
import { MetaService } from "@app/api/meta.service";
import { CanonicalService } from "./core/canonical-service.service";

const log = new Logger("App");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    // do not remove the analytics injection, even if the call in ngOnInit() is removed
    // this injection initializes page tracking through the router
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private i18nService: I18nService,
    private update: UpdateService,
    private authService: AuthenticationService,
    private categoryService: CategoryService,
    private metaService: MetaService,
    private canonicalService: CanonicalService
  ) {
    // ...
  }

  ngOnInit() {
    // alert('siema');
    if (environment.production) {
      Logger.enableProductionMode();
    } else {
      // UserSnap
      // this.enableScript(environment.userSnap);
    }
    if (environment.userSnap.length !== 0) {
      this.enableScript(environment.userSnap);
    }

    log.debug("init");

    this.angulartics2GoogleAnalytics.eventTrack(environment.version, { category: "App initialized" });

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const title = event["title"];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
    if (this.authService.hasToken()) {
      this.authService.restore();
    }
    this.categoryService.index().subscribe();
    onNavigationEnd.subscribe((val) => {
      document.body.scrollTop = 0;
    });

    const script = document.createElement("script");
    script.setAttribute("type", "application/ld+json");
    document.head.appendChild(script);

    onNavigationEnd.subscribe((event) => {
      this.metaService.getStructuredData(this.router.url).subscribe((structuredDataText?: object) => {
        script.textContent = "";

        if (structuredDataText === null) {
          return;
        }

        script.textContent = JSON.stringify(structuredDataText);
      });
    });

    //SEO Canonical url
    this.canonicalService.setCanonicalURL();
  }

  private enableScript(src: string) {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = src;
    const x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
  }
}
