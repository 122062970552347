import { Photo } from "@app/models/Photo";
import { DROP_OPTION } from "@app/core/Helpers";
import { Account } from "@app/models/Account";
import { ShippingPreset } from "@app/models/ShippingPreset";
import { PublicUser } from "@app/models/User";
import { Id } from "@app/core/http/crud-model/id";

interface ReverseAuction {
  id: number;
  created_at: string;
  drop_amount: number;
  drop_interval: number;
  drop_option: DROP_OPTION.DROP_TYPE_VALUE;
  last_update: string;
  minimum_price: number;
  next_price: number;
  next_update: string;
  published_at: string;
  updated_at: string;
  reportable_type: string;
  allow_combined_shipping: boolean;
}

export interface Listing {
  id: number;
  slug: string;
  user_id: number;
  user?: PublicUser;
  category: Listing.Category;
  description: string;
  listable?: ReverseAuction;
  listable_id: any;
  listable_type: "App\\Eloquent\\ReverseAuction";
  listing_status_id: number;
  extra_filters_values: Listing.ExtraFilterValue[];
  price: number;
  current_price: number;
  quantity: number;
  title: string;
  updated_at: string;
  created_at: string;
  photos: Photo[];
  available_quantity: number;
  domestic_shipping_provider: Account.Settings.SHIPPING_PROVIDER;
  domestic_single_price: number;
  domestic_watfo_insurance: boolean;
  international_shipping_provider: Account.Settings.SHIPPING_PROVIDER;
  international_single_price: number;
  international_watfo_insurance: boolean;
  shipping_presets?: ShippingPreset[];
  shipping_country_code: string;
  reportable_type: string;
  authorized_to_buy: boolean;
  available_countries: string[];
  return_policy: string;
  return_policy_id: Listing.RETURN_POLICY_ID;
  url: string;
  active_until: string;
}

export namespace Listing {
  export interface ExtraFilterValue {
    value: string;
    id: number;
  }

  export enum RETURN_POLICY_ID {
    WATFO = 1,
    SELLER = 2,
  }

  export interface Category {
    id: Id;
    title: string;
    slug: string;
    description: string;
    children?: Category[];
    parent_id: number;
    count?: number;
  }

  export namespace Category {
    export interface ExtraFilter {
      id: string;
      name: string;
      admin_name: string;
      values: ExtraFilterValue[];
    }
  }

  export type ExcludedCategorySlug = "a8baa565-uncategorized";

  export class ExcludedCategories {
    public static UNCATEGORIZED: ExcludedCategorySlug = "a8baa565-uncategorized";
  }

  export interface Sortable {
    sort: "title" | "price" | "created_at";
    direction: "asc" | "desc";
  }

  export interface SaleFormat {
    name: "Reverse" | "Standard";
    // value: ''
    value_name: "reverse" | "standard";
  }

  export interface Status {
    name: "All but Archived" | "Draft" | "Pending Review" | "Published" | "Sold" | "Archived";
    value_name: null | "draft" | "pending" | "published" | "sold" | "archived";
    value?: StatusId;
    icon?: string;
  }

  export enum StatusId {
    "BLOCKED" = 0,
    "DRAFT" = 1,
    "PENDING" = 2,
    "PUBLISHED" = 3,
    "SOLD" = 4,
    "FINISHED" = 5,
    "ARCHIVED" = 6,
  }

  export const STATUS_ICONS = ["block", "create", "timelapse", "show_chart", "attach_money", "close"];

  export interface Slide {
    title: string;
    subtitle: string;
    description: string;
    link: string;
    link_label: string;
  }

  export interface Slider {
    category_id: number;
    name: string;
    url: string;
    slides: Slide[];
  }

  export const SHIPPING_KEYS = [
    "unit_system_id",
    "domestic_shipping_provider",
    "domestic_watfo_insurance",
    "domestic_single_price",
    "international_shipping_provider",
    "international_watfo_insurance",
    "international_single_price",
    "shipping_presets_ids",
    "width",
    "height",
    "length",
    "weight",
    "ra_allow_combined_shipping",
  ];
}
