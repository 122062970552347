import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Logger} from '@app/core/logger.service';
import {Listing, Paged} from '@app/models';
import {CategoryService} from '@app/api/category.service';

const logger = new Logger('ListingService');

@Injectable({
  providedIn: 'root'
})
export class ListingService {
  categoryService: CategoryService;

  constructor(public httpClient: HttpClient,
              injector: Injector) {
    this.categoryService = injector.get(CategoryService);
  }

  get(listingSlug: string): Observable<Listing> {
    return this.httpClient
      .get(`/listing/${listingSlug}/`)
      .pipe(
        map((response: Listing) => {
          return response;
        }),
        // catchError(() => of('Error, could not load joke :-('))
      );
  }

  index(categorySlug: string, searchQuery: HttpParams): Observable<Paged<Listing>> {
    return this.httpClient
      .get(`/listing/category/${categorySlug}`, {params: searchQuery})
      .pipe(
        map((response: Paged<Listing>) => response)
      );
  }

  popularItems(categorySlug: string, params: HttpParams = null): Observable<Listing[]> {
    if (params === null) {
      params = new HttpParams();
    }

    return this.httpClient
      .get<Listing[]>(`/listing/category/${categorySlug}/popularItems`, {
        params: params,
      });
  }

  latestItems(categorySlug: string) {
    return this.httpClient
      .get(`/listing/category/${categorySlug}/latestItems`)
      .pipe(
        map((response: Listing[]) => response)
      );
  }

  mainCategoryUrlWithNameAndSlug(listing: Listing) {
    return this.categoryService.getMainCategory(listing.category)
      .pipe(
        map((mainCategory: Listing.Category) => {
          return mainCategory ?
            {
              name: mainCategory.title,
              url: `/listings/${mainCategory.slug}/category/${mainCategory.slug}`,
              slug: mainCategory.slug
            } : {name: '', url: '', slug: ''};
        })
      );
  }

  getEligibleForShipping(listing: Listing, params: {} = {}) {
    return this.httpClient.get(`/listing/${listing.slug}/combinedShipping`, {
      params: new HttpParams({
        fromObject: params,
      })
    })
      .pipe(
        map((response: { seller_name: string, listings: Listing[] }) => response)
      );
  }

  getBySeller(sellerName: string, params: HttpParams) {
    return this.httpClient.get(`/listing/getBySeller/${sellerName}`, {params: params})
      .pipe(
        map((response: Paged<Listing>) => response)
      );
  }

  getReverseShippingRate(listing: Listing) {
    return this.httpClient
      .get(`/listing/${listing.slug}/reverseShippingRate`)
      .pipe(
        map((response: { amount: number, label: string }) => response)
      );

  }

  authorizePayment(listing: Listing) {
    return this.httpClient.post(`/listing/${listing.slug}/authorize`, {})
      .pipe(
        map((r: any) => r)
      );
  }

  captureAuthorization(params: any) {
    return this.httpClient.post(`/listing/captureAuthorization`, params)
      .pipe(
        map((r: any) => r)
      );
  }
  getTaxRate(listing: Listing) {
    return this.httpClient.get(`/listing/${listing.slug}/taxRate`)
      .pipe(
        map((r: number) => {
          logger.info('RATE', r);
          return r;
        } )
      )

  }

}
