import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Account, Paged} from '@app/models';
import Message = Account.Message;
import Thread = Account.Thread;

@Injectable({
  providedIn: 'root'
})
export class InboxService {

  constructor(private httpClient: HttpClient) {
  }

  get(searchParams: HttpParams) {
    return this.httpClient
      .get('/account/inbox', {params: searchParams})
      .pipe(
        map((response: Paged<Account.Thread>) => response)
      );
  }

  getThread(threadId: string) {
    return this.httpClient
      .get(`/account/inbox/${threadId}`)
      .pipe(
        map((response: Account.Thread) => response)
      );
  }

  getThreadMessages(threadId: number, params: HttpParams) {
    return this.httpClient
      .get(`/account/inbox/${threadId}/message`, {params: params})
      .pipe(
        map((response: Paged<Message>) => response)
      );
  }

  addMessage(threadId: number, message: string) {
    return this.httpClient
      .post(`/account/inbox/${threadId}/message`, {message: message})
      .pipe(
        map((response: Paged<Message>) => response)
      );
  }

  askSeller(listingId: number, message: string) {
    return this.httpClient
      .post('/account/inbox/message', {listing_id: listingId, message: message})
      .pipe(
        map((response: Thread) => response)
      );
  }

  createShippingRequest() {
    return this.httpClient
      .post('/account/inbox/shippingRequest', {})
      .pipe(
        map((response: Thread) => response)
      );
  }

  getShippingRequestsUsers() {
    return this.httpClient
      .get('/account/inbox/shippingRequest')
      .pipe(
        map((response: any) => response)
      );
  }

  createOrderThread(orderId: number, body: object = {}) {
    const requestData = {
      is_shipping_request: body && Object.keys(body).length > 0 && body.constructor === Object,
    };

    return this.httpClient
      .post(`/account/inbox/order/${orderId}`, {
        ...requestData,
        ...body,
      })
      .pipe(
        map((response: Thread) => response)
      );
  }
}
