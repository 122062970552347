<div class="mat-typography" fxFill fxLayout="column" fxLayoutAlign="center center">
  <div class="logo--container">
    <div fxLayout="row" fxLayoutAlign="center center">
      <a class="logo" routerLink="/">
        <img alt="" src="assets/logo-white.svg" />
      </a>

      <!--<h4 class="version">v{{version}}</h4>-->
      <!--<div class="language-selector">-->
      <!--<button mat-raised-button color="primary" [matMenuTriggerFor]="languageMenu">-->
      <!--{{currentLanguage}}-->
      <!--</button>-->
      <!--<mat-menu #languageMenu="matMenu">-->
      <!--<button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">-->
      <!--{{language}}-->
      <!--</button>-->
      <!--</mat-menu>-->
      <!--</div>-->
    </div>
  </div>

  <div class="login-container" fxLayout="row" fxLayoutAlign="center">
    <mat-card class="login-box" fxFlex="100%">
      <form (ngSubmit)="login($event)" [formGroup]="loginForm" novalidate>
        <div [hidden]="!error || isLoading" class="login-error" translate>Username or password incorrect.</div>
        <br />
        <div fxLayout="column">
          <mat-form-field>
            <input
              [formControl]="loginForm.get('email')"
              [placeholder]="'Email' | translate"
              autocomplete="email"
              matInput
              type="email"
            />
            <mat-error *ngIf="loginForm.get('email').valid || loginForm.get('email').untouched">
              <span translate>Username is required</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              [formControl]="loginForm.get('password')"
              [placeholder]="'Password' | translate"
              autocomplete="current-password"
              matInput
              required
              type="password"
            />
            <mat-error *ngIf="loginForm.get('password').valid || loginForm.get('password').untouched">
              <span translate>Password is required</span>
            </mat-error>
          </mat-form-field>

          <div *ngIf="showRecaptcha" [formControl]="loginForm.get('captcha')" appRecaptcha ngDefaultControl></div>

          <div class="login--button--container" fxLayoutAlign="space-between center">
            <div fxFlex>
              <mat-slide-toggle [formControl]="loginForm.get('remember_me')" color="primary" translate
                >Remember me
              </mat-slide-toggle>
              <button (click)="showResetFormEvent($event)" mat-button>I forgot my password</button>
            </div>
            <button
              (click)="login($event)"
              [disabled]="!loginForm.valid || isLoading"
              color="primary"
              mat-raised-button
            >
              <app-loader [isLoading]="isLoading" class="inline-loader"></app-loader>
              <span translate>Login</span>
            </button>
          </div>
        </div>
      </form>

      <form
        (ngSubmit)="createResetRequest()"
        *ngIf="showResetForm"
        [formGroup]="resetRequestForm"
        style="padding-top: 20px; position: relative"
      >
        <mat-divider></mat-divider>
        <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="space-between center" style="margin-top: 10px">
          <div fxFlex>
            <mat-form-field>
              <input [formControl]="resetRequestForm.get('email')" matInput placeholder="Email Address" type="email" />
              <mat-error *ngIf="resetRequestForm.invalid || !resetRequestForm.pristine"
                >Please provide a valid and registered email address.
              </mat-error>
              <mat-hint *ngIf="resetRequestInvalid" class="warn">We can't find your email address.</mat-hint>
            </mat-form-field>
          </div>
          <div fxFlex="nogrow" fxFlexOffset="5">
            <button
              [disabled]="resetRequestForm.invalid || creatingRequest"
              color="primary"
              mat-button
              mat-stroked-button
              type="submit"
            >
              <app-loader [isLoading]="creatingRequest" class="inline-loader"></app-loader>
              <span>Reset Password</span>
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</div>
