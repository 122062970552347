<div class="dialog--container">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    <div [innerHTML]="message"></div>
  </div>
  <div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button style="margin-right: 10px">No Thanks</button>
    <button *ngIf="!showPayPalButton" (click)="confirm()" [color]="color" cdkFocusInitial mat-flat-button>
      {{ confirmLabel }}
    </button>
    <div *ngIf="showPayPalButton" class="paypal-button-wrap">
      <app-loader class="inline-loader"></app-loader>
      <div class="paypalButton"></div>
    </div>
  </div>
</div>
