import {Component, OnInit} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {fromEvent, Observable} from 'rxjs';


@Component({
  selector: 'app-listings-shell',
  templateUrl: './listings-shell.component.html',
  styleUrls: ['./listings-shell.component.scss']
})
export class ListingsShellComponent implements OnInit {

  user$: Observable<boolean>;
  categoriesTreeControl: FlatTreeControl<any>;

  constructor() {
    this.categoriesTreeControl = new FlatTreeControl<any>(this._getLevel, this._isExpandable);

  }

  private _getLevel(node: any) {
    return 1;
  }

  private _isExpandable(node: any) {
    return true;
  }

  ngOnInit() {
  }

}
