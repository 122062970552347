import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Logger } from '@app/core/logger.service';

const logger = new Logger('RecaptchaService');

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  constructor(public httpClient: HttpClient) {}

  verifyToken(token: string) {
    return this.httpClient.post('/recaptcha/verifyToken', { token });
  }
}
