<div class="dialog--container">
  <h1 mat-dialog-title>Report</h1>
  <div mat-dialog-content>
    <p>Why are you reporting this item ?</p>
    <mat-form-field appearance="outline" fxFill>
      <mat-label>Message</mat-label>
      <textarea
        (keydown)="newReportKeyDown($event)"
        [formControl]="newMessage"
        cdkFocusInitial
        matAutosizeMinRows="1"
        matInput
        matTextareaAutosize
        maxlength="400"
        placeholder="Press Enter to send"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>Cancel</button>
    <button (click)="sendReport()" [disabled]="!newMessage.value" mat-button mat-flat-button>Send</button>
  </div>

</div>
