import { Listing } from "@app/models/Listing";
import { Order } from "@app/models/Order";

export interface Account {
  user_id: number;
  first_name: string;
  last_name: string;
  username: string;
  phone: string;
  date_of_birth: string;
  addresses: Account.Address[];
  notifications: Account.Notification[];
  setting_unit_system_id: Account.Settings.UNIT_SYSTEM;
  setting_default_domestic_shipping: Account.Settings.SHIPPING_PROVIDER;
  setting_default_international_shipping: Account.Settings.SHIPPING_PROVIDER;
  setting_default_international_countries: string[];
  setting_watfo_insurance: boolean;
  setting_notifications_email: boolean;
  paypal_email: string;
  setting_return_policy: string;
  setting_tax_mode_id: number;
  seller_domestic_country_code: string;
  setting_default_category: string;
}

export interface PublicAccount {
  first_name: string;
  last_name: string;
  username: string;
}

export interface UserSlide {
  id: number;
  title: string;
  subtitle?: string;
  description?: string;
  image_url: string;
}

export interface SellerDetails {
  slides: UserSlide[];
}

export namespace Account {
  export interface Address {
    id: number;
    label: string;
    name: string;
    company: string;
    street1: string;
    street_no: string;
    street2: string;
    street3: string;
    city: string;
    zip: string;
    state: string;
    country: string;
    phone: string;
    is_default: boolean;
  }

  enum NotificationType {
    "INFO",
    "WARNING",
  }

  export interface Notification {
    id: string;
    data: string;
    type: NotificationType;
  }

  export interface Message {
    message: string;
    read_at: string;
    user_id: number;
    created_at: string;
  }

  export interface Thread {
    id: number;
    subject: string;
    messages: Message[];
    listing: Listing;
    image?: string;
    last_message: Message;
    status_id: ThreadStatus;
    read: boolean;
    reportable_type: string;
    order: Order;
    order_id: number;
  }

  export enum ThreadStatus {
    "ARCHIVE" = 0,
    "OPEN" = 1,
  }

  export namespace Settings {
    export enum SHIPPING_PROVIDER {
      WATFO = 1,
      MY_OWN = 2,
    }

    export enum UNIT_SYSTEM {
      "IMPERIAL" = 1,
      "METRIC" = 2,
    }
  }
}
