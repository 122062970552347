import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormControl, Validators} from '@angular/forms';
import {OrderService} from '@app/api';
import {logger} from 'codelyzer/util/logger';

@Component({
  selector: 'app-report',
  templateUrl: './rate-order.component.html',
  styleUrls: ['./rate-order.component.scss']
})
export class RateOrderComponent implements OnInit {
  orderId: any;
  newMessage: FormControl;
  userRating = 0;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RateOrderComponent>,
    private snackBar: MatSnackBar,
    public orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: any }
  ) {
    this.orderId = data.orderId;
    this.newMessage = new FormControl(null, [Validators.required, Validators.minLength(1)]);
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  rateOrder() {
    this.orderService.rateOrder(this.orderId, this.userRating, this.newMessage.value.trim())
      .subscribe((reportable: any) => {
        this.dialogRef.close(reportable);
        this.snackBar.open('Feedback Sent!');
      }, error1 => {
        this.dialogRef.close(false);
        logger.error(error1);
        this.snackBar.open(error1.error.message);
      });
  }

  newReportKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.newMessage.patchValue(this.newMessage.value.trim());
      this.rateOrder();
    }
  }

  getStarSource(position: number) {
    return this.userRating >= position ? 'star' : 'star_border';
  }

}
