import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateModule } from "@ngx-translate/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { Angulartics2Module } from "angulartics2";

import { environment } from "@env/environment";
import { CoreModule } from "@app/core";
import { SharedModule } from "@app/shared";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { APP_BASE_HREF, DatePipe } from "@angular/common";
import { ApiModule } from "@app/api/api.module";
import { MAT_LABEL_GLOBAL_OPTIONS } from "@angular/material/core";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    ApiModule,
    CoreModule,
    SharedModule,
    Angulartics2Module.forRoot(),
    AppRoutingModule,
  ],
  declarations: [AppComponent],
  providers: [
    DatePipe,
    { provide: APP_BASE_HREF, useValue: "/" },
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: "never" } },
    // {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true }},
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 6500,
        verticalPosition: "bottom",
        horizontalPosition: "end",
        panelClass: "default--top--snack",
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
