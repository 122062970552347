import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Page} from '@app/models';
import {StaticPagesService} from '@app/api/static-pages.service';
import { Logger } from '@app/core/logger.service';

const logger = new Logger('ShippingInfoComponent');

@Component({
  selector: 'app-shipping-info',
  templateUrl: './shipping-info.component.html',
  styleUrls: ['./shipping-info.component.scss']
})
export class ShippingInfoComponent implements OnInit {
  page: Page;
  isLoading = true;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ShippingInfoComponent>,
    private staticPagesService: StaticPagesService,
  ) {
  }

  ngOnInit() {
    this.staticPagesService.getPage('shipping-guide').subscribe(
      (page: Page) => {
        this.page = page;
        this.isLoading = false;
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
