import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FlexLayoutModule } from "@angular/flex-layout";

import { MaterialModule } from "@app/material.module";
import { RouteReusableStrategy } from "./route-reusable-strategy";
import { AuthenticationService } from "./authentication/authentication.service";
import { AuthenticationGuard } from "./authentication/authentication.guard";
import { I18nService } from "./i18n.service";
import { HttpService } from "./http/http.service";
import { HttpCacheService } from "./http/http-cache.service";
import { ApiPrefixInterceptor } from "./http/api-prefix.interceptor";
import { ErrorHandlerInterceptor } from "./http/error-handler.interceptor";
import { CacheInterceptor } from "./http/cache.interceptor";
import { LoginComponent } from "@app/core/authentication/login/login.component";
import { SharedModule } from "@app/shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { UpdateService } from "@app/core/update-service.service";
import { SignupComponent } from "@app/core/authentication/signup/signup.component";

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FlexLayoutModule.withConfig({addFlexToParent: false}),
    MaterialModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
  ],
  declarations: [LoginComponent, SignupComponent],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    I18nService,
    HttpCacheService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    UpdateService,
    {
      provide: HttpClient,
      useClass: HttpService,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
  ],
  exports: [],
  bootstrap: [LoginComponent, SignupComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
