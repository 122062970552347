import {Route as ngRoute, Routes} from '@angular/router';

import {ListingsShellComponent} from '@app/listings/listing-shell/listings-shell.component';
import {MyAccountShellComponent} from '@app/my-account/my-account-shell/my-account-shell.component';

import {AuthenticationGuard} from './authentication/authentication.guard';
import {MAIN_CATEGORIES} from '@app/core/Helpers';

/**
 * Provides helper methods to create routes.
 */
export class Route {

  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return {Route} The new route using shell as the base.
   */

  static auctionsShell(routes: Routes): ngRoute {
    return {
      path: '',
      component: ListingsShellComponent,
      children: routes,
      // canActivate: [AuthenticationGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true }
    };
  }

  static myAccountShell(routes: Routes): ngRoute {
    return {
      path: '',
      component: MyAccountShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      runGuardsAndResolvers: 'always',
           // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true, main_category_slug: MAIN_CATEGORIES.HYPE.slug, account_view: true }
    };
  }

}
