import { PublicAccount } from '@app/models/Account';

export interface User {
  id: number;
  uuid: number;
  email: string;
  password: string;
  user_role_id: User.ROLE;
  user_status_id: User.STATUS;
  created_at: Date;
  updated_at: Date;
  // token: User.Credentials;
}

export interface PublicUser {
  uuid: number;
  account?: PublicAccount;
}

export namespace User {
  export enum ROLE { BUYER = 1, SELLER = 2, VERIFIED_SELLER = 3 }
  export enum STATUS {UNVERIFIED = 0, ACTIVE = 1, BLOCKED = 2}

  export interface LoginContext {
    username: string;
    password: string;
    remember_me?: boolean;
  }
  export interface SignupContext {
    username: string;
    password: string;
    terms: boolean;
  }
  export interface Token {
    access_token: string;
    expires_at: Date;
    token_type: 'Bearer' | 'Explicit';
  }
  export interface UpdatePasswordContext {
    password: string;
    password_confirmation: string;
    old_password?: string;
    token?: string;
    email?: string;
  }
  export interface PasswordResetContext {
    email: string;
  }
  export interface VerifyEmailContext {
    uuid: string;
    signature: string;
  }
}

