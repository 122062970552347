import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "@env/environment";
import { Logger } from "@app/core/logger.service";
import { BehaviorSubjectsService } from "../authentication/behavior-subjects.service";

const log = new Logger("ErrorHandlerInterceptor");

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  constructor(private behaviorSubjectsService: BehaviorSubjectsService) {}

  // Customize the default error handler here if needed
  // private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
  private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (response.status === 423) {
      this.behaviorSubjectsService.turnOnPrivateMode.next(true);
    }
    if (!environment.production) {
      // Do something with the error
      log.error("Request error", response);
    }
    throw response;
  }
}
