import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Listing, Order } from "@app/models";
import { catchError, map } from "rxjs/operators";
import { Cart } from "@app/models/Cart";
import { Helpers } from "@app/core/Helpers";
import { Logger } from "@app/core/logger.service";
import * as moment from "moment";

const logger = new Logger("CartService");

@Injectable({
  providedIn: "root",
})
export class CartService {
  private readonly _cart: BehaviorSubject<Cart>;

  constructor(public httpClient: HttpClient) {
    this._cart = new BehaviorSubject(this.emptyCart());
    this.get().subscribe();
  }

  get cart() {
    return this._cart;
  }

  emptyCart(): Cart {
    return {
      items: [],
      valid_until: moment.utc(), // .add('15', 'm'),
      updated_at: moment.utc(),
      shipping: { shipping_from: "", total: 0 },
      cart_total: 0,
      cart_tax: 0,
      shipping_country_code: "US",
    };
  }

  get(): Observable<Cart> {
    return this.httpClient.get("/account/cart").pipe(
      map((cartApi: Cart) => {
        return this.setCart(cartApi);
      })
    );
  }

  setCart(cart: Cart) {
    logger.info(cart);
    cart.shipping = { shipping_from: Helpers.printCountryName("US"), total: 0 };
    cart.valid_until = moment.utc(cart.valid_until);
    cart.updated_at = moment.utc(cart.updated_at);
    this._cart.next(cart);
    return cart;
  }

  addToCart(listing: Listing, quantity: number = 1) {
    return this.httpClient.post(`/account/cart/${listing.id}`, { quantity: quantity }).pipe(
      map((cartApi: Cart) => {
        return this.setCart(cartApi);
      })
    );
  }

  update(listing: Listing, quantity: number = 1) {
    return this.httpClient.put(`/account/cart/${listing.id}`, { quantity: quantity }).pipe(
      map((cartApi: Cart) => {
        return this.setCart(cartApi);
      }),
      catchError((er) => {
        logger.error(er);
        return throwError(er);
      })
    );
  }

  delete(listing: Listing) {
    return this.httpClient.delete(`/account/cart/${listing.id}`).pipe(
      map((cartApi: Cart) => {
        return this.setCart(cartApi);
      }),
      catchError((er) => {
        logger.error(er);
        return throwError(er);
      })
    );
  }

  clear() {
    return this.httpClient.delete("/account/cart").pipe(
      map((cartApi: Cart) => {
        return this.setCart(cartApi);
      }),
      catchError((er) => {
        logger.error(er);
        return throwError(er);
      })
    );
  }

  getShippingRates() {
    return this.httpClient.get("/account/cart/shippingRates").pipe(
      map((rates: any) => {
        logger.info(rates);
        return rates;
      }),
      catchError((er) => {
        logger.error(er);
        return throwError(er);
      })
    );
  }

  paypalPayment(id: any) {
    return this.httpClient.post("/account/order", { id: id }).pipe(
      map((order: any) => {
        return order;
      }),
      catchError((er) => {
        logger.error(er);
        return throwError(er);
      })
    );
  }

  paypalPaymentCapture(id: any) {
    return this.httpClient.post("/account/order/capture", id).pipe(
      map((order: any) => {
        return order;
      }),
      catchError((er) => {
        logger.error(er);
        return throwError(er);
      })
    );
  }

  buyReverseAuction(listing: Listing, holdForCombinedShipping: boolean = false) {
    return this.httpClient
      .post(`/account/cart/${listing.id}/buyReverseAuction`, {
        hold_for_combined_shipping: holdForCombinedShipping,
      })
      .pipe(
        map((order: Order) => {
          // this.setCart(this.emptyCart());
          return order;
        }),
        catchError((er) => {
          logger.error(er);
          return throwError(er);
        })
      );
  }

  // formatCartResponse(cartApi: Cart): Cart {
  //   logger.info(cartApi);
  //   const orderItems: Cart.OrderItem[] = [];
  //   cartApi.items.forEach((listing, idx) => {
  //     orderItems.push({quantity: 1, listing: listing, total: listing.price });
  //   });
  //   const shipping: Shipping = {
  //     shipping_from: Helpers.printCountryName('US'),
  //     total: cartApi.shipping_cost
  //   };
  //   const cart: Cart = {
  //     items: orderItems,
  //     shipping: shipping,
  //     updated_at: moment.utc(cartApi.updated_at),
  //     valid_until: moment.utc(cartApi.valid_until), // .add('15', 'm')
  //     cart_total: cartApi.cart_total
  //   };
  //   return cart;
  // }
}
