import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Account, Listing} from '@app/models';
import {FormControl, Validators} from '@angular/forms';
import {ReportService} from '@app/api';
import {logger} from 'codelyzer/util/logger';
import Thread = Account.Thread;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  reportable: any;
  newMessage: FormControl;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportComponent>,
    private snackBar: MatSnackBar,
    public reportService: ReportService,
    @Inject(MAT_DIALOG_DATA) public data: {reportable: any}
  ) { 
    this.reportable = data.reportable;
    this.newMessage = new FormControl(null, [Validators.required, Validators.minLength(1)]);
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  sendReport() {
    this.reportService.addReport(this.reportable, this.newMessage.value.trim())
      .subscribe((reportable: any) => {
        this.dialogRef.close(reportable);
        this.snackBar.open('Report send');
      }, error1 => {
        this.dialogRef.close();
        logger.error(error1);
        this.snackBar.open(error1.error.message);
      });
  }
  newReportKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.newMessage.patchValue(this.newMessage.value.trim());
      this.sendReport();
    }
  }

}
