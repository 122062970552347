import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { LoginComponent } from "./login/login.component";
import { Logger } from "@app/core/logger.service";
import { ActivatedRoute } from "@angular/router";
import { User } from "@app/models";
import { UserService } from "@app/api/user.service";
import { SignupComponent } from "./signup/signup.component";
import { catchError, map, switchMap } from "rxjs/operators";
import { environment } from "@env/environment";
import { isPlatformBrowser } from "@angular/common";

const logger = new Logger("AuthenticationService");

const tokenKey = "watfoAuthToken";

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  private loginCount = 0;

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const savedCredentials = sessionStorage.getItem(tokenKey) || localStorage.getItem(tokenKey);
      if (savedCredentials) {
        this._token = JSON.parse(savedCredentials);
      }
      if (environment.production) {
        this.user.asObservable().subscribe((user: User) => {
          logger.debug(user);
        });
      }
    }
  }

  private _token: User.Token | null;

  /**
   * Gets the user token.
   * @return {Credentials} The user token or null if the user is not authenticated.
   */
  get token(): User.Token | null {
    return this._token;
  }

  private _user = new BehaviorSubject<User>(null);

  /**
   * Determine whether to show the Auth-only UI
   * @returns {Observable<boolean>}
   */

  get user(): BehaviorSubject<User> {
    return this._user;
  }

  /**
   * Authenticates the user.
   * @param {LoginContext} context The login parameters.
   * @return {Observable<User>} The user token.
   */
  loginTest(context: User.LoginContext): Observable<User> {
    // Replace by proper authentication call
    const sub = this.userService.login(context);
    sub.subscribe(
      (response: any) => {
        this.loginCount++;
        this.setToken(response.token, context.remember_me);
        this._user.next(response.user);
        logger.error(this.loginCount);
      },
      (error: Error) => {
        this._user.next(null);
        logger.error("ERROR", error);
      }
    );
    // return sub.pipe();
    return sub.pipe(switchMap((response: any) => of(response.user)));
  }

  login(context: User.LoginContext): Observable<User> {
    return this.userService.login(context).pipe(
      map((response) => {
        logger.info("RESPONSE:TOKEN", response);
        this.setToken(response.token, context.remember_me);
        this._user.next(response.user);
        return response.user;
      }),
      catchError((err) => {
        logger.error(err);
        this._user.next(null);
        return throwError(err);
      })
    );
  }

  signupTest(context: User.SignupContext) {
    const sub = this.userService.signup(context);
    sub.subscribe(
      (response) => {
        this.setToken(response.token, false);
        this._user.next(response.user);
      },
      (error: Error) => {
        this._user.next(null);
        logger.error("ERROR", error);
      }
    );
    return sub;
  }

  signup(context: User.SignupContext) {
    return this.userService.signup(context).pipe(
      switchMap((response) => {
        this.setToken(response.token, false);
        this._user.next(response.user);
        return of(response.user);
      }),
      catchError((err) => {
        logger.error(err);
        this._user.next(null);
        return throwError(err);
      })
    );
  }

  restore() {
    this.userService.get().subscribe(
      (user: User) => {
        this._user.next(user);
      },
      (error1) => {
        this._user.next(null);
        logger.error(error1);
      }
    );
  }

  hasValidCredentials() {
    return this.userService.get().pipe(
      map((user: User) => {
        this._user.next(user);
        return of(user);
      })
    );
  }

  /**
   * Logs out the user and clear token.
   * @return {Observable<boolean>} True if the user was logged out successfully.
   */
  logoutTest(): Observable<boolean> {
    const sub = this.userService.logout();
    sub.subscribe(
      (value) => {
        logger.info("LOGOUT:RESP", value);
        this._user.next(null);
        this.setToken();
      },
      (error1) => {
        logger.error("LOGOUT:RESP", error1);
        this._user.next(null);
        this.setToken();
      }
    );
    return sub;
  }

  logout(): Observable<boolean> {
    return this.userService.logout().pipe(
      map(() => {
        this._user.next(null);
        return true;
      }),
      catchError((err) => {
        this._user.next(null);
        logger.error(err);
        return throwError(err);
      })
    );
  }

  createPasswordResetRequest(context: User.PasswordResetContext) {
    return this.userService.createResetPasswordRequest(context);
  }

  resetPassword(context: User.UpdatePasswordContext) {
    return this.userService.resetPassword(context).pipe(
      map((response) => {
        this.setToken(response.token, false);
        this._user.next(response.user);
        return response.user;
      })
    );
  }

  verifyEmailAddress(context: User.VerifyEmailContext) {
    return this.userService.verifyEmail(context);
  }

  updatePassword(context: User.UpdatePasswordContext) {
    return this.userService.update(context);
  }

  /**
   * Checks is the user is authenticated.
   * @return {boolean} True if the user is authenticated.
   */
  hasToken(): boolean {
    return !!this.token;
  }

  /**
   * Show Login component for any route - not only Authentication guard
   * @returns {Observable<any>}
   */

  showLoginComponent(): Observable<any> {
    const dialogRef = this.dialog.open(LoginComponent, {
      data: {
        activeRoute: this.activatedRoute,
        authService: this, // avoid circular dependency warning
      },
    });
    return dialogRef.afterClosed();
  }

  showRegisterComponent(): Observable<any> {
    const dialogRef = this.dialog.open(SignupComponent, {
      data: {
        activeRoute: this.activatedRoute,
        authService: this, // avoid circular dependency warning
      },
    });
    return dialogRef.afterClosed();
  }

  updateUser() {
    return this.userService.get().pipe(
      map((u: User) => {
        this._user.next(u);
        return u;
      })
    );
  }

  /**
   * Sets the user token.
   * The token may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the token are only persisted for the current session.
   * @param {Credentials=} token The user token.
   * @param {boolean=} remember True to remember token across sessions.
   */
  private setToken(token?: User.Token, remember?: boolean) {
    this._token = token || null;

    if (isPlatformBrowser(this.platformId)) {
      if (token) {
        const storage = remember ? localStorage : sessionStorage;
        storage.setItem(tokenKey, JSON.stringify(token));
      } else {
        sessionStorage.removeItem(tokenKey);
        localStorage.removeItem(tokenKey);
      }
    }
  }
}
