import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Page } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class StaticPagesService {

  constructor(private httpClient: HttpClient) {
  }

  index() {
    return this.httpClient
      .get('/page')
      .pipe(
        map((response: Page[]) => response)
      );
  }

  getPage(slug: string) {
    return this.httpClient
      .get(`/page/${slug}`)
      .pipe(
        map((response: Page) => response)
      );
  }
}
