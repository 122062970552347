<div class="dialog--container">
  <h1 mat-dialog-title>Rate Order</h1>
  <div mat-dialog-content>
    <p flex fxLayoutAlign="start center">Give us your feedback! It's important. Would You recommend this seller ? </p>
    <mat-form-field appearance="outline" fxFill>
      <mat-label>Message</mat-label>
      <textarea
        (keydown)="newReportKeyDown($event)"
        [formControl]="newMessage"
        cdkFocusInitial
        matAutosizeMinRows="1"
        matInput
        matTextareaAutosize
        maxlength="400"
        placeholder="Press Enter to send"></textarea>
    </mat-form-field>
    <p flex fxLayoutAlign="start center"><span style="margin-right: 20px; ">Rate: </span>
      <mat-icon (click)="userRating = position"
                *ngFor="let position of [1,2,3,4,5]">{{getStarSource(position)}}</mat-icon>
    </p>
  </div>
  <div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>No Thanks</button>
    <button (click)="rateOrder()" [disabled]="!newMessage.value && userRating !== 0" mat-button mat-flat-button>Send
    </button>
  </div>

</div>
