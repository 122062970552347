import { EventEmitter, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Logger } from "@app/core/logger.service";

import { map, tap } from "rxjs/operators";
import { User } from "@app/models/";
import { AUTH_EVENT } from "@app/core/Helpers";

const logger = new Logger("UserService");

@Injectable({
  providedIn: "root",
})
export class UserService {
  public authEventsEmmiter = new EventEmitter<AUTH_EVENT>();

  constructor(public httpClient: HttpClient) {}

  private _user: User;

  get user() {
    return this._user;
  }

  set user(user: User | null) {
    this._user = user;
    this.authEventsEmmiter.emit(AUTH_EVENT.LOGIN);
  }

  login(context: User.LoginContext) {
    return this.httpClient.post("/auth/login", context).pipe(
      map((response: any): { user: User; token: User.Token } => response),
      tap((response) => (this.user = response.user))
    );
  }

  signup(context: User.SignupContext) {
    return this.httpClient.post("/auth/signup", context).pipe(
      map((response: any): { user: User; token: User.Token } => response),
      tap((response) => (this.user = response.user))
    );
  }

  get() {
    return this.httpClient.get("/auth/user").pipe(
      map((user: User) => user),
      tap((user) => (this.user = user))
    );
  }

  logout() {
    return this.httpClient.post("/auth/logout", {}).pipe(
      map((response) => {
        this.user = null;
        this.authEventsEmmiter.emit(AUTH_EVENT.LOGOUT);
        return true;
      })
    );
  }

  update(context: User.UpdatePasswordContext) {
    return this.httpClient.put("/auth/user", context).pipe(map((user: User) => user));
  }

  createResetPasswordRequest(context: User.PasswordResetContext) {
    return this.httpClient.post("/auth/password/create", context).pipe(map((response: any) => response.message));
  }

  resetPassword(context: User.UpdatePasswordContext) {
    return this.httpClient
      .post("/auth/password/reset", context)
      .pipe(map((response: any): { user: User; token: User.Token } => response));
  }

  verifyEmail(context: User.VerifyEmailContext) {
    return this.httpClient.post("/auth/verify", context).pipe(map((response: any) => response.message));
  }

  recaptcha(token: string) {
    return this.httpClient.post("/auth/recaptcha", { params: { token } }).pipe(
      map((response: any) => {
        logger.debug(response);
        return response;
      })
    );
  }
}
