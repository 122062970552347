import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SwUpdate} from '@angular/service-worker';
import {Logger} from '@app/core/logger.service';

const log = new Logger('UpdateService');

@Injectable()
export class UpdateService {
  constructor(private swUpdate: SwUpdate, private snackBar: MatSnackBar) {
    this.swUpdate.available.subscribe(evt => {
      const snack = this.snackBar.open('Update Available', 'Update', {
        verticalPosition: 'top',
        horizontalPosition: 'center'
      });
      log.info('UpdateAvailable');
      snack
        .onAction()
        .subscribe(() => {
          window.location.reload();
        });
      setTimeout(() => {
        snack.dismissWithAction();
      }, 10000);
    });
  }
}
