import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ShippingPreset} from '@app/models/ShippingPreset';
import {catchError, map} from 'rxjs/operators';
import {BehaviorSubject, throwError} from 'rxjs';
import {Logger} from '@app/core/logger.service';
import {Listing} from '@app/models';

const PRESET_URL = '/account/shipping/preset';
const logger = new Logger('ShippingService');

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  shippingPreset$ = new BehaviorSubject<ShippingPreset[]>([]);

  constructor(public httpClient: HttpClient) {
    this.getShippingPresets()
      .subscribe((value) => {
        this.shippingPreset$.next(value);
      });
  }

  getShippingPresets() {
    return this.httpClient
      .get(PRESET_URL)
      .pipe(
        map((response: ShippingPreset[]) => {
          return response;
        })
      );
  }

  createShippingPreset(shippingPreset: ShippingPreset) {
    return this.httpClient
      .post(PRESET_URL, shippingPreset)
      .pipe(
        map((response: ShippingPreset[]) => {
          this.shippingPreset$.next(response);
          return response;
        })
      );
  }

  deleteShippingPreset(shippingPreset: ShippingPreset) {
    return this.httpClient
      .delete(`${PRESET_URL}/${shippingPreset.id}`)
      .pipe(
        map((response: ShippingPreset[]) => {
          this.shippingPreset$.next(response);
          return response;
        })
      );
  }

  updateShippingPreset(shippingPreset: ShippingPreset) {
    return this.httpClient
      .put(`${PRESET_URL}/${shippingPreset.id}`, shippingPreset)
      .pipe(
        map((response: ShippingPreset[]) => {
          this.shippingPreset$.next(response);
          return response;
        })
      );
  }

  listingUpdateShipping(listing: Listing, shippingData: any) {
    return this.httpClient
      .put(`/account/listing/${listing.id}/updateShipping`, shippingData)
      .pipe(
        map((response: Listing) => {
          logger.info(response);
          this.getShippingPresets()
            .subscribe((value) => {
              this.shippingPreset$.next(value);
            });
          return response;
        }),
        catchError((err => {
          logger.error(err);
          return throwError(err);
        }))
      );
  }
}
