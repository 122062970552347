<mat-drawer-container>
  <!--<mat-drawer mode="side" opened="true" class="mat-elevation-z4">-->
  <!--<app-my-account-sidebar></app-my-account-sidebar>-->
  <!--</mat-drawer>-->
  <mat-drawer-content>
    <app-header></app-header>
    <div class="container" style="padding: 20px">
      <div fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between start">
        <h1 class="title" fxFlex="100" fxLayoutAlign="space-between center">
          <div *ngIf="account$.value.username">
            <span
              >Hello <strong>{{ account$.value.username }}</strong
              >!</span
            >
            <span class="mat-body-2 color--hint" *ngIf="cart$.value.items.length > 0">
              <span fxHide.lt-md>Your Shopping Cart is valid for another</span>
              <mat-icon fxShow.lt-md style="font-size: 14px; width: 14px; position: relative; top: 2px"
                >shopping_cart</mat-icon
              >
              <span class="color--primary">{{ cartValid | async }}</span>
            </span>
          </div>
          <span *ngIf="user$.value.user_status_id > User.STATUS.UNVERIFIED && !account$.value.first_name">
            Please finish setting your account to make orders.
          </span>

          <span class="color--warn" *ngIf="user$.value.user_status_id === User.STATUS.UNVERIFIED">
            Please Verify Your Email Address to Set Up your account.
          </span>
        </h1>
        <div class="account--routing" fxHide.xs fxFlex="nogrow" fxLayout="row" fxLayoutGap="10px">
          <a mat-button [routerLink]="APP_ROUTES.MY_ACCOUNT.path" color="primary"> Profile </a>
          <a
            mat-button
            [routerLink]="APP_ROUTES.BUYER_URL.path"
            mat-stroked-button
            [routerLinkActive]="['mat-flat-button']"
            color="primary"
          >
            Orders
          </a>
          <a
            *ngIf="user$.value.user_role_id >= User.ROLE.SELLER"
            mat-button
            mat-stroked-button
            [routerLinkActive]="['mat-flat-button']"
            [routerLink]="APP_ROUTES.SELLER_URL.path"
            color="primary"
          >
            Listings
          </a>
          <a
            *ngIf="user$.value.user_role_id >= User.ROLE.SELLER"
            mat-button
            mat-stroked-button
            [routerLinkActive]="['mat-flat-button']"
            [routerLink]="APP_ROUTES.SELLER_SOLD_URL.path"
            color="primary"
          >
            Sold
          </a>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>
