// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// Client ID: 4
// Client Secret: xRVg9XfaUnTi4UM62QSpm2Qi56yDbw8GIfTL5ZhQ
// `.env.ts` is generated by the `npm run env` command
import env from "./.env";

export const environment = {
  production: false,
  version: env.npm_package_version + "-dev",
  serverUrl: "https://staging-api.watfo.com/api",
  defaultLanguage: "en-US",
  supportedLanguages: ["en-US"],
  recaptcha: {
    site_key: "6LcKoBcaAAAAAJ20I8VKO890dwG1cZxQs08TGyTu", // real key
  },
  userSnap: "//api.usersnap.com/load/b4b6f9b9-8fc4-4bf1-86f0-0dd7cfedebd6.js",
  paypalClientId: "AUNNX52-kiitQwSsxCpUfLeNWGYTkud_gOSGVPImxZ2yXs3Eu4QKpdJr1ViocPmOrMbMzpi8U91A4QaY",
  merchantId: "A23Z3S7FVF838",
};
