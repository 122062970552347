import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { APP_ROUTES } from "../Helpers";

@Injectable({
  providedIn: "root",
})
export class PrivateBetaReverseGuard implements CanActivate {
  APP_ROUTES = APP_ROUTES;

  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let betaPrivateToken = localStorage.getItem("betaPrivateToken") ? false : true;

    if (!betaPrivateToken) {
      this.router.navigate([APP_ROUTES.HOME_PAGE.path]);
    }
    return betaPrivateToken;
  }
}
