import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-photo-dialog',
  templateUrl: './photo-dialog.component.html',
  styleUrls: ['./photo-dialog.component.scss']
})
export class PhotoDialogComponent {
  img: any;

  constructor (public dialogRef: MatDialogRef<PhotoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {
                img: any
              })
  {
    this.img = data.img;
  }

}
