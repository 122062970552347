import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class MetaService {
  constructor(public httpClient: HttpClient) {}

  getStructuredData(currentUrl: string) {
    return this.httpClient
      .get("/meta/page-structured-data", {
        params: {
          current_url: currentUrl,
        },
      })
      .pipe(map((response: { data?: object }) => response.data));
  }

  getClientIp() {
    return this.httpClient.get("/meta/client");
  }
}
