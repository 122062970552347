import {
  Directive,
  OnInit,
  ElementRef,
  AfterViewInit,
  NgZone,
  Injector,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NgControl,
  AbstractControl,
  AsyncValidatorFn,
  Validators,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from "@angular/forms";
import { RecaptchaService } from "@app/api/recaptcha.service";
import { environment } from "@env/environment";
import { Logger } from "@app/core/logger.service";
import { map } from "rxjs/operators";


const logger = new Logger("RecaptchaDirective");

export interface ReCaptchaConfig {
  theme?: "dark" | "light";
  type?: "audio" | "image";
  size?: "compact" | "normal";
  tabindex?: number;
}

declare const grecaptcha: any;

declare global {
  interface Window {
    grecaptcha: any;
    reCaptchaLoad: () => void;
  }
}

@Directive({
  selector: "[appRecaptcha]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecaptchaDirective),
      multi: true,
    },
  ],
})
export class RecaptchaDirective implements OnInit, AfterViewInit, ControlValueAccessor {
  private onChange: (value: string) => void;
  private onTouched: (value: string) => void;
  private control: FormControl;
  private key: string = environment.recaptcha.site_key;

  @Input() config: ReCaptchaConfig = {};
  @Input() lang: string;

  @Output() captchaResponse = new EventEmitter<string>();
  @Output() captchaExpired = new EventEmitter();

  private widgetId: number;

  constructor(
    private element: ElementRef,
    private ngZone: NgZone,
    private injector: Injector,
    private recaptchaService: RecaptchaService
  ) {}

  ngOnInit() {
    this.registerReCaptchaCallback();
    this.addScript();
  }

  ngAfterViewInit() {
    this.control = this.injector.get(NgControl).control as FormControl;
    this.setValidators();
  }

  registerReCaptchaCallback() {
    window.reCaptchaLoad = () => {
      const config = {
        ...this.config,
        sitekey: this.key,
        callback: this.onSuccess.bind(this),
        "expired-callback": this.onExpired.bind(this),
      };
      this.widgetId = this.render(this.element.nativeElement, config);
    };
  }

  private render(element: HTMLElement, config: ReCaptchaConfig): number {
    return grecaptcha.render(element, config);
  }

  addScript() {
    let script = document.createElement("script");
    const lang = this.lang ? "&hl=" + this.lang : "";
    script.src = `https://www.google.com/recaptcha/api.js?onload=reCaptchaLoad&render=explicit${lang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  writeValue(obj: any): void {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onExpired() {
    this.ngZone.run(() => {
      this.captchaExpired.emit();
      this.onChange(null);
      this.onTouched(null);
    });
  }

  onSuccess(token: string) {
    this.ngZone.run(() => {
      // this.verifyToken(token);
      this.captchaResponse.next(token);
      this.onChange(token);
      this.onTouched(token);
    });
  }

  verifyToken(token: string) {
    this.control.setValidators(null);
    this.control.updateValueAndValidity();
    this.control.setErrors({ required: true });
    this.control.markAsDirty();
    this.control.setAsyncValidators(this.validateToken(token));
    this.control.updateValueAndValidity();
  }

  validateToken(token: string): AsyncValidatorFn {
    return (AC: AbstractControl): any => {
      return this.recaptchaService.verifyToken(token).pipe(
        map((response: any) => {
          if (!response.success) {
            return { tokenInvalid: true };
          }
          return null;
        })
      );
    };
  }

  /**
   * Calling the setValidators doesn't trigger any update or value change event.
   * Therefore, we need to call updateValueAndValidity to trigger the update
   */
  private setValidators() {
    this.control.setValidators(Validators.required);
    this.control.updateValueAndValidity();
  }
}
