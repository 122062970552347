import { Injectable, Injector } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Listing, Paged, PagedWishlist, User } from "@app/models";
import { BehaviorSubject } from "rxjs";
import { Logger } from '@app/core/logger.service';
import { AuthenticationService } from "@app/core/authentication/authentication.service";

const logger = new Logger("WishlistService");

@Injectable({
  providedIn: "root",
})
export class WishlistService {
  authService: AuthenticationService;
  private readonly _wishlistItems = new BehaviorSubject([]);

  constructor(private httpClient: HttpClient, injector: Injector) {
    this.authService = injector.get(AuthenticationService);
    this.authService.user.asObservable().subscribe((user: User) => {
      if (!user) {
        this.setIds([]);
        return;
      }
      if (user) {
        this.httpClient.get("/account/wishlist/ids").subscribe((ids: number[]) => this.setIds(ids ? ids : []));
      }
    });
  }

  get wishlistItems() {
    return this._wishlistItems;
  }

  get(params: HttpParams) {
    return this.httpClient
      .get("/account/wishlist/", { params: params })
      .pipe(map((response: PagedWishlist<Listing>) => response));
  }

  addListing(listing: Listing) {
    return this.httpClient.post(`/account/wishlist/${listing.id}`, {}).pipe(
      map((response: Paged<Listing>) => {
        this.setIds([...this._wishlistItems.getValue(), listing.id]);
        return response;
      })
    );
  }

  deleteListing(listing: Listing) {
    return this.httpClient.delete(`/account/wishlist/${listing.id}`).pipe(
      map((response: Paged<Listing>) => {
        // logger.info(this._wishlistItems.getValue(), listing.id);
        this.setIds(this._wishlistItems.getValue().filter((id) => id !== listing.id));
        return response;
      })
    );
  }

  setIds(ids: number[]) {
    this._wishlistItems.next(ids);
  }

  clear() {
    return this.httpClient.delete(`/account/wishlist`).pipe(
      map((response: Paged<Listing>) => {
        this.setIds([]); // clears ids
        return response;
      })
    );
  }
}
