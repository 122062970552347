import {Component, Injector, OnInit} from '@angular/core';
import {AccountService} from '@app/api/account.service';
import {BehaviorSubject, interval, Observable} from 'rxjs';
import {Account} from '@app/models/Account';
import {User} from '@app/models/User';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {APP_ROUTES} from '@app/core/Helpers';
import * as moment from 'moment';
import {map, startWith} from 'rxjs/operators';
import {Logger} from '@app/core/logger.service';
import {Cart} from '@app/models/Cart';
import {CartService} from '@app/api/cart.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const logger = new Logger('MyAccountShellComponent');


@Component({
  selector: 'app-my-account-shell',
  templateUrl: './my-account-shell.component.html',
  styleUrls: ['./my-account-shell.component.scss']
})
export class MyAccountShellComponent implements OnInit {
  account$: BehaviorSubject<Account>;
  user$: BehaviorSubject<User>;
  User = User;
  APP_ROUTES = APP_ROUTES;
  cartValid: Observable<string>;
  cart$: BehaviorSubject<Cart>;
  active_until: moment.Moment = moment.utc();

  constructor(public accountService: AccountService, injector: Injector, public snackBar: MatSnackBar) {

    this.account$ = accountService.account;
    this.user$ = injector.get(AuthenticationService).user;
    accountService.get().subscribe();
    const cartService = injector.get(CartService);
    this.cart$ = cartService.cart;
    this.cart$
      .subscribe((cart: Cart) => {
      this.active_until = cart.valid_until;
    });
    // const end = moment.utc().add(15, 'm');
    this.cartValid = interval(1000)
      .pipe(
        startWith(0),
        map((i) => {
          const diff = moment.utc(this.active_until.diff(moment.utc()));
          if (moment.utc().isAfter(moment.utc(this.active_until))) {
            cartService.clear()
              .subscribe(() => {
                this.snackBar.open('Cart is no longer valid');
              });
          }
          return diff.format('HH:mm:ss');
        })
      );
  }

  ngOnInit() {

  }


}
