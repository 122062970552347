import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BehaviorSubjectsService {
  turnOnPrivateMode = new BehaviorSubject(false);

  constructor() {}
}
