<div class="dialog--container">
  <h1 mat-dialog-title>{{listing?.title}}</h1>
  <div mat-dialog-content>
    <p>What would you like to know ?</p>
    <mat-form-field appearance="outline" fxFill>
      <mat-label>Message</mat-label>
      <textarea
        matInput
        maxlength="400"
        matTextareaAutosize
        matAutosizeMinRows="1"
        [formControl]="newMessage"
        cdkFocusInitial
        (keydown)="newMessageKeyDown($event)"
        placeholder="Press Enter to send"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-flat-button (click)="sendButtonClick()" color="primary" cdkFocusInitial>Send</button>
  </div>

</div>
