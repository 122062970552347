import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
})
export class ConfirmationDialogComponent implements OnInit {
  message: string;
  confirmLabel: string;
  title: string;
  color: string;
  showPayPalButton = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      message: string;
      confirmLabel: string;
      title?: string;
      color?: string;
      showPayPalButton?: boolean;
    }
  ) {
    this.message = data.message;
    this.confirmLabel = data.confirmLabel;
    this.title = data.title ? data.title : "Are you Sure?";
    this.color = data.color ? data.color : "primary";
    this.showPayPalButton = data.showPayPalButton ? data.showPayPalButton : false;
  }

  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
