import { Component, Inject, OnInit } from "@angular/core";
import { environment } from "@env/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "@app/core/authentication/authentication.service";
import { Router } from "@angular/router";
import { I18nService } from "@app/core/i18n.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { finalize } from "rxjs/operators";
import { Logger } from "@app/core/logger.service";
import { CustomValidators } from "@app/core/CustomValidators";
import { MetaService } from "@app/api/meta.service";

const log = new Logger("RegisterComponent");

@Component({
  selector: "app-register",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  version: string = environment.version;
  error: string[] = [];
  registerForm: FormGroup;
  isLoading = false;
  authenticationService: AuthenticationService;

  isGhost = false;
  showRecaptcha = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private metaService: MetaService,
    private i18nService: I18nService,
    public dialogRef: MatDialogRef<SignupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.createForm();
    this.authenticationService = this.data.authService;
  }

  ngOnInit() {
    log.info(this.data);
    this.showRecaptchaForValidation();
  }

  showRecaptchaForValidation() {
    this.metaService.getClientIp().subscribe((res: any) => {
      this.isGhost = res.data.is_ghost;
      if (this.isGhost === false) {
        this.showRecaptcha = true;
      }
    });
  }

  signup() {
    this.isLoading = true;
    this.authenticationService
      .signup(this.registerForm.value)
      .pipe(
        finalize(() => {
          this.registerForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (credentials) => {
          // log.debug(`${credentials.username} successfully logged in`);
          this.dialogRef.close(credentials);
          // this.router.navigate(['/'], { replaceUrl: true });
        },
        (error) => {
          this.error = [];
          const errors = error.error.errors;
          Object.keys(errors).forEach((errObj) => {
            this.error.push(errors[errObj][0]);
          });
        }
      );
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    this.registerForm = this.formBuilder.group(
      {
        email: ["", [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(8)]],
        password_confirmation: ["", [Validators.required]],
        captcha: ["test", Validators.required],
        terms: [false, [Validators.required]],
      },
      {
        validator: CustomValidators.Match("password", "password_confirmation"),
      }
    );
  }
}
