import { Inject, Injectable, Injector, PLATFORM_ID } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError, timer } from "rxjs";
import { Account, Listing, User } from "@app/models";
import { Logger } from "@app/core/logger.service";
import { catchError, finalize, map, startWith, switchMap, tap } from "rxjs/operators";
import { environment } from "@env/environment";
import { Photo } from "@app/models/Photo";
import { Paged } from "@app/models/Paged";
import { AuthenticationService } from "@app/core/authentication/authentication.service";
import { isPlatformBrowser } from "@angular/common";

const logger = new Logger("AccountService");
const defaultCategoryKey = "defaultCategory";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  authService: AuthenticationService;
  private readonly _account: BehaviorSubject<Account>;

  constructor(public httpClient: HttpClient, public injector: Injector, @Inject(PLATFORM_ID) private platformId: any) {
    this._account = new BehaviorSubject(this.emptyAccount());
    this.authService = injector.get(AuthenticationService);
    if (!environment.production) {
      this._account.asObservable().subscribe((account: Account) => {
        logger.debug("SERVICE:", account);
      });
    }

    this.authService.user.asObservable().subscribe((val: User) => {
      if (!val) {
        this.account.next(this.emptyAccount());
      }
    });
  }

  /*
   index() {
   return interval(1000 * 30)
   .pipe(
   switchMap(() => this.apiGet())
   );
   }*/
  get account() {
    return this._account;
  }

  get sellerCountryCode(): string {
    return this.account.value["seller_domestic_country_code"];
  }

  get() {
    return this.httpClient.get("/account/").pipe(
      map((response: Account) => {
        this.setAccount(response);
        return response;
      }),
      catchError((er) => {
        this._account.next(this.emptyAccount());
        return throwError(er);
      })
    );
  }

  setAccount(account: Account) {
    this._account.next(account);
  }

  update(account: Account) {
    return this.httpClient.put("/account/", account).pipe(
      map((response: Account) => {
        this.setAccount(response);
        return response;
      })
    );
  }

  notificationMarkAsRead(notification: Account.Notification) {
    return this.httpClient.put(`/account/notification/${notification.id}`, {}).pipe(
      map((message) => {
        const account = this.account.getValue();
        account.notifications = this.account.value.notifications.filter((n) => n.id !== notification.id);
        this.setAccount(account);
        return message;
      })
    );
  }

  notificationDeleteAll() {
    return this.httpClient.delete(`/account/notification`).pipe(
      map((a: Account) => {
        this.setAccount(a);
        return a;
      })
    );
  }

  addressCreate(address: Account.Address) {
    return this.httpClient.post("/account/address/", address).pipe(
      map((account: Account) => {
        this.setAccount(account);
        return account;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  addressDelete(address: Account.Address) {
    return this.httpClient.delete(`/account/address/${address.id}`, {}).pipe(
      map((account: Account) => {
        this.setAccount(account);
        return account;
      })
    );
  }

  addressSetDefault(address: Account.Address) {
    return this.httpClient.get(`/account/address/default/${address.id}`).pipe(
      map((account: Account) => {
        this.setAccount(account);
        return account;
      })
    );
  }

  listingSearch(searchParams: HttpParams) {
    return this.httpClient
      .get("/account/listing", { params: searchParams })
      .pipe(map((response: Paged<Listing>) => response));
  }

  listingGet(listingId: number) {
    return this.httpClient.get(`/account/listing/${listingId}`).pipe(
      map((response: Listing) => {
        return response;
      })
    );
  }

  listingCreate(listing: Listing): Observable<Listing> {
    return this.httpClient.post("/account/listing/", listing).pipe(
      map((response: Listing) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  listingUpdate(listing: Listing): Observable<Listing> {
    return this.httpClient.put(`/account/listing/${listing.id}`, listing).pipe(
      map((response: Listing) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  listingGetReturnPolicyExample(policyId: Listing.RETURN_POLICY_ID) {
    return this.httpClient.post(`/account/getReturnPolicyExample`, { return_policy_id: policyId }).pipe(
      map((response: string) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  listingAddPhoto(listingId: number, photo: FormData) {
    return this.httpClient
      .post(`/account/listing/${listingId}/photo`, photo)
      .pipe(map((response: Listing) => response));
  }

  listingUpdatePhoto(listingId: number, photo: Photo) {
    // Route::put('/{listing}/photo/{photo}', 'Api\Account\PhotosController@update')->name('api.account.photo.update');
    // Route::delete('/{listing}/photo/{photo}', 'Api\Account\PhotosController@delete')->name('api.account.photo.delete');
    return this.httpClient
      .put(`/account/listing/${listingId}/photo/${photo.id}`, photo)
      .pipe(map((response: Listing) => response));
  }

  listingDeletePhoto(listingId: number, photoId: number) {
    return this.httpClient
      .delete(`/account/listing/${listingId}/photo/${photoId}`)
      .pipe(map((response: Listing) => response));
  }

  listingSubmit(listingId: number) {
    return this.httpClient.get(`/account/listing/${listingId}/submit`).pipe(map((response: Listing) => response));
  }

  listingUnpublish(listingId: number) {
    return this.httpClient.get(`/account/listing/${listingId}/unpublish`).pipe(map((response: Listing) => response));
  }

  listingDuplicate(listingId: number) {
    return this.httpClient.get(`/account/listing/${listingId}/copy`).pipe(map((response: Listing) => response));
  }

  listingImportFromEbay(ebayId: string) {
    return this.httpClient.get(`/account/external/listing/${ebayId}/ebayItem`).pipe(
      map((response: Listing) => {
        return response;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  categoryIndex() {
    return this.httpClient.get("/account/category").pipe(
      map((response: Listing.Category[]) => {
        return response;
      })
    );
  }

  categoryGetFilters(category: Listing.Category): Observable<Listing.Category.ExtraFilter[]> {
    return this.httpClient
      .get(`/account/category/${category.id}/extraFilter`)
      .pipe(map((response: Listing.Category.ExtraFilter[]) => response));
  }

  updateSettings(settingFields: any) {
    return this.httpClient.post("/account/settings", settingFields).pipe(
      map((response: Account) => {
        this.setAccount(response);
        return response;
      })
    );
  }

  getShippogCarriers() {
    return this.httpClient
      .get("/account/settings/shippoCarriers")
      .pipe(map((response: { id: string; carrier: string }) => response));
  }

  sellerOnboarding() {
    return this.httpClient.get("/account/onboarding/signup").pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  sellerOnboardingCheck() {
    return this.httpClient.get("/account/onboarding/check").pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  sellerOnboardingCode(code: {}) {
    return this.httpClient.post("/account/payments/onboarding", code).pipe(
      map((response: Account) => {
        this.setAccount(response);
        return response;
      })
    );
  }

  getDefaultDomesticShippingProvider() {
    return this.account.getValue().setting_default_domestic_shipping;
  }

  getDefaultIntlShippingProvider() {
    return this.account.getValue().setting_default_international_shipping;
  }

  getDefaultWatfoInsurance() {
    return this.account.getValue().setting_watfo_insurance;
  }

  counters() {
    return timer(0, 1000 * 60).pipe(
      switchMap(() =>
        this.authService.user.getValue()
          ? this.httpClient.get(`/account/counters`)
          : of({
              messages: 0,
              notifications: 0,
              orders_received: 0,
              orders_made: 0,
            })
      ),
      map((r) => r)
    );
  }

  getTaxableState(mode: number) {
    return this.httpClient.post("/account/taxes/taxableStates", { mode }).pipe(map((r) => r));
  }

  setDefaultCategory(category_slug: string) {
    if (isPlatformBrowser(this.platformId)) {
      return this.httpClient.post("/account/settings/defaultCategory", { category: category_slug }).pipe(
        map((response: Account) => {
          return response;
        }),
        finalize(() => {
          localStorage.setItem(defaultCategoryKey, category_slug);
        })
      );
    }
  }

  getDefaultCategory(): Observable<Listing.Category> {
    if (isPlatformBrowser(this.platformId)) {
      const defaultCategory = localStorage.getItem(defaultCategoryKey);
      if (defaultCategory) {
        return this.httpClient.get(`/category/${defaultCategory}`).pipe(map((r: Listing.Category) => r));
      }
      return this.httpClient.get("/account/settings/defaultCategory").pipe(
        map((response: Listing.Category) => {
          return response;
        })
      );
    }
  }

  listingArchive(listing: Listing) {
    return this.httpClient.delete(`/account/listing/${listing.id}`);
  }

  private emptyAccount(): Account {
    return {
      user_id: null,
      first_name: null,
      addresses: [],
      notifications: [],
      date_of_birth: null,
      last_name: null,
      phone: null,
      username: null,
      setting_default_domestic_shipping: null,
      setting_default_international_countries: null,
      setting_default_international_shipping: null,
      setting_unit_system_id: null,
      setting_notifications_email: null,
      setting_watfo_insurance: null,
      paypal_email: null,
      setting_return_policy: null,
      seller_domestic_country_code: null,
      setting_tax_mode_id: 0,
      setting_default_category: null,
    };
  }
}
