import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@app/material.module";
import { LoaderComponent } from "./loader/loader.component";
import { TranslateModule } from "@ngx-translate/core";
import { FooterComponent } from "./footer/footer.component";
import { SearchBarComponent } from "./search-bar/search-bar.component";
import { HeaderComponent } from "./header/header.component";
import { RouterModule } from "@angular/router";
import { MainCategorySliderComponent } from "./main-category-slider/main-category-slider.component";
import { AskSellerComponent } from "./ask-seller/ask-seller.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ReportComponent } from "./report/report.component";
import { RateOrderComponent } from "@app/shared/rate-order/rate-order.component";
import { PellComponent } from "./pell/pell.component";
import { RecaptchaDirective } from "./recaptcha.directive";
import { ShippingInfoComponent } from "./shipping-info/shipping-info.component";
import { PhotoDialogComponent } from "./photo-dialog/photo-dialog.component";
import { TaxCalculatorComponent } from "@app/listings/shared/tax-calculator/tax-calculator.component";
import { RequestShippingDialogComponent } from "./request-shipping-dialog/request-shipping-dialog.component";
import { ShippingDialogComponent } from "./shipping-dialog/shipping-dialog.component";
import { HeaderV2Component } from "@app/shared/header-v2/header-v2.component";
import { HomePageSliderComponent } from "./home-page-slider/home-page-slider.component";
import { VarDirective } from "@app/shared/ng-var.directive";
import { SwiperModule } from "ngx-swiper-wrapper";
import { SWIPER_CONFIG } from "ngx-swiper-wrapper";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: "horizontal",
  slidesPerView: "auto",
};
@NgModule({
  imports: [
    FlexLayoutModule.withConfig({ addFlexToParent: false }),
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    SwiperModule,
  ],
  declarations: [
    HeaderV2Component,
    LoaderComponent,
    FooterComponent,
    SearchBarComponent,
    HeaderComponent,
    MainCategorySliderComponent,
    AskSellerComponent,
    ConfirmationDialogComponent,
    ReportComponent,
    RateOrderComponent,
    PellComponent,
    RecaptchaDirective,
    VarDirective,
    ShippingInfoComponent,
    PhotoDialogComponent,
    RequestShippingDialogComponent,
    ShippingDialogComponent,
    PhotoDialogComponent,
    TaxCalculatorComponent,
    HomePageSliderComponent,
  ],
  exports: [
    HeaderV2Component,
    LoaderComponent,
    FooterComponent,
    SearchBarComponent,
    HeaderComponent,
    MainCategorySliderComponent,
    AskSellerComponent,
    ReportComponent,
    RateOrderComponent,
    PellComponent,
    RecaptchaDirective,
    ShippingInfoComponent,
    TaxCalculatorComponent,
    ShippingInfoComponent,
    RequestShippingDialogComponent,
    ShippingDialogComponent,
    HomePageSliderComponent,
    SwiperModule,
  ],
  bootstrap: [
    AskSellerComponent,
    ConfirmationDialogComponent,
    ReportComponent,
    RateOrderComponent,
    ShippingInfoComponent,
    PhotoDialogComponent,
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
})
export class SharedModule {}
