import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";

import { environment } from "@env/environment";
import { Logger } from "@app/core/logger.service";
import { AuthenticationService } from "@app/core/authentication/authentication.service";
import { tap } from "rxjs/operators";

/**
 * Prefixes all requests with `environment.serverUrl`.
 */
const logger = new Logger("ApiPrefixInterceptor");

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService: AuthenticationService = this.injector.get(AuthenticationService);

    let betaPrivateToken =
      localStorage.getItem("betaPrivateToken") !== null ? localStorage.getItem("betaPrivateToken") : "";

    if (authService.hasToken()) {
      request = request.clone({
        url: environment.serverUrl + request.url,
        setHeaders: {
          Authorization: `Bearer ${authService.token.access_token}`,
          "Private-Beta-Token": betaPrivateToken,
        },
      });
    } else {
      request = request.clone({
        url: environment.serverUrl + request.url,
        //after turning off private beta, You can delete this headers with private-beta-token
        setHeaders: {
          "Private-Beta-Token": betaPrivateToken,
        },
      });
    }
    logger.debug(request);
    return next.handle(request).pipe(
      tap((r) => {
        if (r instanceof HttpResponse) {
          logger.debug(r);
        }
      })
    );
  }
}
