import {Cart} from '@app/models/Cart';
import {Account} from '@app/models/Account';
import ShippingRate = Cart.ShippingRate;
import { PublicUser } from '@app/models/User';

export interface Order {
  id: number;
  uuid: string;
  seller?: PublicUser;
  seller_id: number;
  buyer_id: number;
  items: Cart.OrderItem[];
  amount_total: number;
  amount_items_total: number;
  amount_shipping: number;
  order_status_id: Order.STATUS_ID;
  created_at: string;
  updated_at: string;
  shipping_address: Account.Address;
  billing_address: Account.Address;
  shipping: Cart.Shipping;
  shipping_rate: ShippingRate;
  payments: Order.Payment[];
  address_to: Account.Address;
  address_from: Account.Address;
  reportable_type: string;
  amount_tax: number;
  rating?: Order.Rating;
  is_combined_shipping: boolean;
  parent_id: number;
  can_request_shipping: boolean;
  children?: Order[],
}

export namespace Order {
  export enum STATUS_ID {
    'PENDING_PAYMENT' = 1,
    'PAID' = 2,
    'SHIPPED' = 3,
    'COMPLETED' = 4,
    'OPEN' = 5,
    'SHIPPING_REQUESTED' = 6,
    'CANCELLED' = 10,
  }

  export interface Payment {
    id: string;
    paypal_transaction: string;
  }

  export interface Rating {
    comment: string;
    rating: number;
    created_at: string;
    updated_at: string;
  }
}
