import {Injectable, Injector} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {Logger} from '../logger.service';
import {AuthenticationService} from './authentication.service';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

const logger = new Logger('AuthenticationGuard');

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private injector: Injector
              ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const authService: AuthenticationService = this.injector.get(AuthenticationService);
    return authService.hasValidCredentials()
      .pipe(switchMap((user) => of(!!user)),
        catchError(err => {
          const sub = authService.showLoginComponent();
          sub.subscribe(success => {
            if (!success) {
              this.router.navigate(['/']);
            }
          });
          return sub;
        }));
  }


}
