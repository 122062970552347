import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Account, Listing} from '@app/models';
import {FormControl, Validators} from '@angular/forms';
import {InboxService} from '@app/api';
import {logger} from 'codelyzer/util/logger';
import Thread = Account.Thread;

@Component({
  selector: 'app-ask-seller',
  templateUrl: './ask-seller.component.html',
  styleUrls: ['./ask-seller.component.scss']
})
export class AskSellerComponent implements OnInit {
  listing: Listing;
  newMessage: FormControl;
  constructor(
    public dialogRef: MatDialogRef<AskSellerComponent>,
    public inboxService: InboxService,
    @Inject(MAT_DIALOG_DATA) public data: {listing: Listing}) {
    this.listing = data.listing;
    this.newMessage = new FormControl(null, [Validators.required, Validators.minLength(1)]);
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  sendMessage() {
    this.inboxService.askSeller(this.listing.id, this.newMessage.value.trim())
      .subscribe((thread: Thread) => {
        this.dialogRef.close(thread);
      }, error1 => {
        logger.error(error1);
      });
  }
  newMessageKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.newMessage.patchValue(this.newMessage.value.trim());
      this.sendMessage();
    }
  }
  sendButtonClick() {
    this.newMessage.patchValue(this.newMessage.value.trim());
    this.sendMessage();
  }

}
