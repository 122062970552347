import {AbstractControl} from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {

  /**
   * Match two controls if they are the same
   * @param firstControlName
   * @param secondControlName
   * @returns {(AC: AbstractControl) => any}
   * @constructor
   */
  static Match(firstControlName: any, secondControlName: any): any {
    return (AC: AbstractControl): any => {
      const firstControlValue = AC.get(firstControlName).value; // to index value in input tag
      const secondControlValue = AC.get(secondControlName).value; // to index value in input tag
      if (firstControlValue !== secondControlValue) {
        AC.get(secondControlName).setErrors({MatchFields: true});
      } else {
        return null;
      }
    };
  }

  static BeforeEighteenYearsAgo(AC: AbstractControl) {
    if (AC && AC.value && moment(AC.value).isAfter(moment().subtract(18, 'year'))) {
      return {'beforeEighteenYearsAgo': true};
    }
    return null;
  }
}
