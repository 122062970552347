<div class="mat-typography" fxFill fxLayout="column" fxLayoutAlign="center center">
  <div class="logo--container">
    <div fxLayout="row" fxLayoutAlign="center center">
      <a class="logo" routerLink="/">
        <img alt="" src="assets/logo-white.svg" />
      </a>

      <!--<h4 class="version">v{{version}}</h4>-->
      <!--<div class="language-selector">-->
      <!--<button mat-raised-button color="primary" [matMenuTriggerFor]="languageMenu">-->
      <!--{{currentLanguage}}-->
      <!--</button>-->
      <!--<mat-menu #languageMenu="matMenu">-->
      <!--<button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">-->
      <!--{{language}}-->
      <!--</button>-->
      <!--</mat-menu>-->
      <!--</div>-->
    </div>
  </div>
  <div class="login-container" fxLayout="row" fxLayoutAlign="center">
    <mat-card class="login-box" fxFlex="100%">
      <form (ngSubmit)="signup()" [formGroup]="registerForm" novalidate>
        <mat-card class="card-info"> Please check spam folder in your inbox </mat-card>
        <div [hidden]="!error || isLoading" class="login-error" translate>
          <ul>
            <li *ngFor="let message of error">
              {{ message }}
            </li>
          </ul>
        </div>
        <br />
        <div fxLayout="column">
          <mat-form-field>
            <input
              [placeholder]="'Email' | translate"
              autocomplete="email"
              formControlName="email"
              matInput
              type="email"
            />
            <mat-error *ngIf="registerForm.controls.email.invalid || registerForm.controls.email.untouched">
              <span translate>Username is required</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              [placeholder]="'Password (min 8 characters)' | translate"
              autocomplete="current-password"
              formControlName="password"
              matInput
              required
              type="password"
            />
            <mat-error *ngIf="registerForm.controls.password.invalid || registerForm.controls.password.untouched">
              <span translate>Password is required. Provide min 8 characters</span>
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              [placeholder]="'Password Confirmation' | translate"
              autocomplete="current-password"
              formControlName="password_confirmation"
              matInput
              required
              type="password"
            />
            <mat-error
              *ngIf="
                registerForm.controls.password_confirmation.invalid ||
                registerForm.controls.password_confirmation.untouched
              "
            >
              <span translate>Password Confirmation is required and has to match password</span>
            </mat-error>
          </mat-form-field>

          <div *ngIf="showRecaptcha" appRecaptcha formControlName="captcha" ngDefaultControl></div>

          <div class="login--button--container">
            <mat-checkbox color="primary" formControlName="terms" required>
              I accept <a routerLink="/terms">Terms and Conditions</a>
            </mat-checkbox>
            <button [disabled]="registerForm.invalid" color="primary" mat-raised-button type="submit">
              <app-loader [isLoading]="isLoading" class="inline-loader"></app-loader>
              <span translate>Register</span>
            </button>
          </div>
        </div>
      </form>
    </mat-card>
  </div>
</div>
