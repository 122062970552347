import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { finalize } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
// import { Logger, I18nService, AuthenticationService } from '../../core/index';
import { I18nService } from "../../i18n.service";
import { Logger } from "../../logger.service";
import { AuthenticationService } from "../authentication.service";

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { User } from "@app/models";
import { MetaService } from "@app/api/meta.service";

const logger = new Logger("LoginComponent");

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  version: string = environment.version;
  error: string;
  loginForm: FormGroup;
  resetRequestForm: FormGroup;
  isLoading = false;
  creatingRequest = false;
  resetRequestInvalid = false;
  authenticationService: AuthenticationService;
  showResetForm = false;

  isGhost = false;
  showRecaptcha = false;

  constructor(
    private router: Router,
    private metaService: MetaService,
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar
  ) {
    this.createForms();
    this.authenticationService = this.data.authService;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  ngOnInit() {
    logger.info(this.data);
    this.showRecaptchaForValidation();
  }

  showRecaptchaForValidation() {
    this.metaService.getClientIp().subscribe((res: any) => {
      this.isGhost = res.data.is_ghost;
      if (this.isGhost === false) {
        this.showRecaptcha = true;
      }
    });
  }

  login(event: Event) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.authenticationService
      .login(this.loginForm.value)
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (user: User) => {
          logger.debug("USER", user);
          this.dialogRef.close(true);
          // this.router.navigate(['/'], { replaceUrl: true });
        },
        (error) => {
          logger.error(`Login error: ${error}`);
          this.error = error;
          // this.dialogRef.close(true);
        }
      );
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  createResetRequest() {
    this.creatingRequest = true;
    this.authenticationService
      .createPasswordResetRequest(this.resetRequestForm.value)
      .pipe(
        finalize(() => {
          this.creatingRequest = false;
          this.resetRequestForm.markAsPristine();
          // this.resetRequestForm.reset();
        })
      )
      .subscribe(
        (message) => {
          logger.info(message);
          const snackBar = this.snackBar.open(message, "My Account");
          this.resetRequestInvalid = false;
          this.dialogRef.close();
        },
        (error) => {
          const errors = error.error.errors;
          Object.keys(errors).forEach((errObj) => {
            this.snackBar.open(errors[errObj][0]);
          });
          this.resetRequestInvalid = true;
        }
      );
  }

  showResetFormEvent(event: MouseEvent) {
    this.showResetForm = true;
  }

  private createForms() {
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
      captcha: ["test", Validators.required],
      remember_me: true,
    });
    this.resetRequestForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }
}
