import { Injectable } from "@angular/core";
import { recursiveFind } from "@app/core/Helpers";
import { Logger } from "@app/core/logger.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Listing } from "@app/models";
import { map, share, switchMap } from "rxjs/operators";
import Slider = Listing.Slider;
import Category = Listing.Category;

const logger = new Logger("CategoryService");
const defaultCategoryKey = "defaultCategory";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  private _categories: Listing.Category[];
  categories$ = new BehaviorSubject<Listing.Category[]>([]);
  // dataChange = new BehaviorSubject<Listing.Category[]>([]);

  // index data(): Listing.Category[] { return this.dataChange.value; }

  constructor(private httpClient: HttpClient) {}
  // index() {
  //   return this.categories$.asObservable();
  // }
  index() {
    const categorySub = of(this._categories).pipe(
      switchMap((cats) => {
        if (cats) {
          return of(cats);
        } else {
          return this.httpClient.get("/category/");
        }
      })
    );
    return categorySub.pipe(
      map((response: Listing.Category[]) => {
        this._categories = response;
        this.categories$.next(response);
        return response;
      })
    );
  }
  getFilters(categorySlug: string): Observable<Listing.Category.ExtraFilter[]> {
    return this.httpClient
      .get(`/category/${categorySlug}/extraFilter`)
      .pipe(map((response: Listing.Category.ExtraFilter[]) => response));
  }
  get(categorySlug: string) {
    return this.httpClient.get(`/category/${categorySlug}`).pipe(map((r: Listing.Category) => r));
  }

  getMainCategory(category: Listing.Category) {
    return this.categories$.asObservable().pipe(
      map((categories) => {
        let mainCategory = null;
        categories.forEach((cat) => {
          const found = recursiveFind(cat.children, "id", category.id);
          if (found) {
            mainCategory = cat;
          }
        });
        return mainCategory;
      })
    );
  }
  getSlider(categorySlug: string, searchParams?: { [param: string]: string | string[] }) {
    return this.httpClient.get(`/category/${categorySlug}/slider`, { params: searchParams }).pipe(
      map((slider: Slider) => {
        return slider;
      })
    );
  }

  getAllMemorabiliaCategory(): Observable<Category> {
    return this.httpClient.get<Category>(`/category/memorabilia`);
  }
}
