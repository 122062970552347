import { FormControl, FormGroup } from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Logger } from "@app/core/logger.service";
import { Account, Listing, Order } from "@app/models";
import { countries } from "typed-countries";

const logger = new Logger("Helpers");

export class HandleServerErrors {
  static clearServerErrorsOnForm(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
      logger.info(key);
      form.get(key).setErrors(null);
    });
  }

  static displayErrorsOnForm(form: FormGroup, error: HttpErrorResponse, labels: {}, snackBar: MatSnackBar) {
    logger.error(error);
    const message = error.error.message,
      validationErrors = error.error.errors;
    snackBar.open(message);
    Object.keys(validationErrors).forEach((key) => {
      let formControl: FormControl = form.get(key) as FormControl;
      if (key.indexOf("[") > -1) {
        let temp = key.replace("[", ".");
        temp = temp.replace("]", "");
        logger.info(temp, form.get(temp));
        if (form.get(temp)) {
          formControl = form.get(temp) as FormControl;
        }
      }
      if (!formControl) {
        return;
      }
      formControl.setErrors({ "server-error": true });
      labels[key] = validationErrors[key][0];
    });
    logger.error(labels);
  }
}

export function recursiveFind(array: Array<any>, key: string, value: any) {
  let o;
  array.some(function iter(a: any) {
    if (a[key] === value) {
      o = a;
      return true;
    }
    return Array.isArray(a.children) && a.children.some(iter);
  });
  return o;
}

export enum AUTH_EVENT {
  LOGOUT = 0,
  LOGIN = 1,
}

export const APP_ROUTES = {
  MY_ACCOUNT: {
    module: "my-account",
    path: "/my-account/profile",
  },
  WISHLIST: {
    module: "wishlist",
    path: "/my-account/wishlist",
  },
  BUYER_URL: {
    module: "orders-made",
    path: "/my-account/orders-made",
  },
  MY_ACCOUNT_ORDER: {
    module: "order/:orderId",
    path: "/my-account/order/ID",
  },
  SELLER_URL: {
    module: "listings",
    path: "/my-account/listings",
  },
  SELLER_SOLD_URL: {
    module: "orders-received",
    path: "/my-account/orders-received",
  },
  SELLER_SHIPPING_PRESETS: {
    module: "shipping-presets",
    path: "/my-account/shipping-presets",
  },
  ADD_LISTING: {
    module: "edit-listing/:listingId",
    path: "/my-account/edit-listing/ID",
  },
  ADD_LISTING_PHOTOS: {
    module: "edit-listing/:listingId/photos",
    path: "/my-account/edit-listing/ID/photos", // replace ID with database id
  },
  ADD_LISTING_PREVIEW: {
    module: "edit-listing/:listingId/preview",
    path: "/my-account/edit-listing/ID/preview", // replace ID with database id
  },
  INBOX: {
    module: "inbox",
    path: "/my-account/inbox",
  },
  INBOX_THREAD: {
    module: "inbox/:threadId",
    path: "/my-account/inbox/ID",
  },
  CART: {
    module: "cart",
    path: "/my-account/cart",
  },
  LISTINGS: {
    module: "listings",
    path: "/listings",
  },
  HOME_PAGE: {
    module: "",
    path: "/",
  },
  ABOUT_PAGE: {
    module: "about",
    path: "about",
  },
  RESET_PASSWORD: {
    module: "reset-password/:token",
    path: "/",
  },
  VERIFY_EMAIL: {
    module: "verify-email/:uuid/:signature",
    path: "/",
  },
  PROCESS_PAYMENT: {
    module: "process-payment",
    path: "/my-account/process-payment",
  },
  PROCESS_AUTHORIZATION: {
    module: "process-authorization",
    path: "/my-account/process-authorization",
  },
  FAQ: {
    module: "faq",
    path: "/faq",
  },
  BETA_PRIVATE: {
    module: "beta-private",
    path: "/beta-private",
  },
  BLOG: {
    module: "blog",
    path: "/blog",
    name: "Blog",
  },
  BLOG_DETAILS: {
    module: "blog/:id",
    path: "/blog/ID",
  },
};

export namespace DROP_OPTION {
  export const TIME_ACTIVE_PLACEHOLDER = "One Week | One Month | Until Sold";
  export const TIME_ACTIVE_OPTIONS = [
    {
      name: "One Week",
      value: 60 * 60 * 24 * 7, // 60secs * 60 min * 24H * 7 days
    },
    {
      name: "One Month / 30 days",
      value: 60 * 60 * 24 * 30,
    },
    {
      name: "Until Sold",
      value: 0,
    },
  ];
  export const DROP_TYPE_PLACEHOLDER = "By % | By Amount";

  export enum DROP_TYPE_VALUE {
    "PERCENT" = 1,
    "AMOUNT" = 2,
  }
  export const DROP_TYPE_OPTIONS = [
    {
      name: "By %",
      value: DROP_TYPE_VALUE.PERCENT,
    },
    {
      name: "By amount",
      value: DROP_TYPE_VALUE.AMOUNT,
    },
  ];
  export const DROP_INTERVAL_PLACEHOLDER = "Every Hour | Every Day | Every Week";
  export const DROP_INTERVAL_OPTIONS = [
    {
      name: "Every 2 Minutes (Test)",
      value: 60 * 2,
    },
    {
      name: "Every 5 Minutes (Test)",
      value: 60 * 5,
    },
    {
      name: "Every Hour",
      value: 60 * 60,
    },
    {
      name: "Every Day",
      value: 60 * 60 * 24,
    },
    {
      name: "Every Week",
      value: 60 * 60 * 24 * 7,
    },
  ];
}

/*
c4ca4238-memorabilia
c81e728d-sports
eccbc87e-baseball
a87ff679-unsigned-memorabilia
e4da3b7f-pre-war-cards
1679091c-post-war-cards
8f14e45f-vintage-photos
 */

interface MainCategory {
  slug: string;
  name: string;
}

export interface CategoryTag {
  img: string;
  title: string;
  slug: string;
}

export const MAIN_CATEGORIES: { [s: string]: MainCategory } = {
  HYPE: {
    slug: "f4b9ec30-streetwear",
    name: "Streetwear",
  },
  MEMORABILIA: {
    slug: "c4ca4238-memorabilia",
    name: "Memorabilia",
  },
};

export const MAIN_TAGS: { [s: string]: CategoryTag } = {
  AUTOGRAPHS: {
    img: "../../assets/homepage_v2/autographs.png",
    title: "Autographs",
    slug: "autographs",
  },
  CARDS: {
    img: "../../assets/homepage_v2/cards.png",
    title: "Cards",
    slug: "cards",
  },
  EVENT_USED: {
    img: "../../assets/homepage_v2/event.png",
    title: "Event Used",
    slug: "event-used",
  },
  OTHER: {
    img: "../../assets/homepage_v2/pokemons.png",
    title: "Other",
    slug: "other",
  },
};

export const HOME_PAGE_CATEGORIES: { [s: string]: CategoryTag } = {
  BASEBALL: {
    img: "../../assets/homepage_v2/baseball.jpg",
    title: "Baseball",
    slug: "eccbc87e-baseball",
  },
  FOOTBALL: {
    img: "../../assets/homepage_v2/football.jpg",
    title: "Football",
    slug: "33e75ff0-football",
  },
  MUSIC: {
    img: "../../assets/homepage_v2/music.jpg",
    title: "Music",
    slug: "a3f390d8-music",
  },
  ENTERTAINMENT: {
    img: "../../assets/homepage_v2/entertainment.jpg",
    title: "Entertainment",
    slug: "35f4a8d4-entertainment",
  },
};

export namespace Helpers {
  export function printCountryName(countryCode: string) {
    return countries.find((c) => c.iso === countryCode) ? countries.find((c) => c.iso === countryCode).name : "N/A";
  }

  export function populateId(path: string, id: string | number) {
    return path.replace("ID", `${id}`);
  }

  export function listingRelativePath(listing: Listing) {
    return `/listings/item/category/${listing.category.slug}/${listing.slug}`;
  }

  export function accountSettings(account: Account) {
    const settingKeys = Object.keys(account).filter((key) => key.indexOf("setting_") !== -1),
      settings = {};
    settingKeys.forEach((key) => {
      settings[key] = account[key];
    });
    return settings;
  }

  export function orderStatusNameById(orderStatusId: Order.STATUS_ID, isCombinedShipping: boolean = false) {
    switch (orderStatusId) {
      case Order.STATUS_ID.SHIPPED:
        return "Order Shipped";
      case Order.STATUS_ID.PENDING_PAYMENT:
        return "Pending Payment";
      case Order.STATUS_ID.OPEN:
        return "Open";
      case Order.STATUS_ID.COMPLETED:
        return "Completed";
      case Order.STATUS_ID.SHIPPING_REQUESTED:
        return "Shipping Requested";
      case Order.STATUS_ID.PAID:
        if (isCombinedShipping) {
          return "Paid";
        }

        return "Awaiting Shipment";
      case Order.STATUS_ID.CANCELLED:
        return "Cancelled";
    }
  }
}
